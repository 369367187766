/* base\_typography.scss */

html {
  font-size: 62.5%;
}

body {
  @include typography(base);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: rem(20px);
  margin-top: rem(20px);

  a {
    color: inherit;
    text-decoration: inherit;
  }

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  @include typography(alpha);
}

h2 {
  @include typography(bravo);
}

h3 {
  @include typography(charlie);
}

h4 {
  @include typography(delta);
}

h5 {
  @include typography(echo);
}

h6 {
  @include typography(foxtrot);
}

hr {
  background-color: $color-border;
  border: none;
  height: rem(1px);
  margin-bottom: rem(30px);
  margin-top: rem(30px);
}

p {
  @include typography(hotel);
  margin-bottom: rem(20px);
  margin-top: rem(20px);
}

p.large {
  @include typography(golf);
}

p.small {
  @include typography(india);
}

small {
  @include typography(juliett);
}

a {
  text-decoration: underline;
  transition-duration: 0.2s;
  transition-property: background, border, color;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

blockquote {
  margin: 0;
}

cite {
  font-style: normal;
}

figure {
  margin: 0;
}

address {
  font-style: normal;
}
