/* components\_sections.scss */

$section-padding: 90px;

.sections {
  //
}

.section {
  @include rfs(rem($section-padding), padding-bottom);
  @include rfs(rem($section-padding), padding-top);
  position: relative;
}

.section__container {
  margin-bottom: rem(-30px);
}

.section__content {
  margin-bottom: rem(30px);
}

.section__header {
  margin-bottom: rem(30px);
  text-align: center;
}

.section__heading {
  //
}

.section__subheading {
  //
}
