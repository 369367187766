/* mixins\_utility.scss */

/**
 * Utility mixin that resets the default <ul> and <li> styles.
 */
@mixin reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * Utility mixin that resets the default <button> styles.
 */
@mixin reset-button {
  appearance: none;
  background: none;
  border: none;
  padding: 0;
}
