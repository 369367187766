/* components/_background.scss */

.background {
  @include position(absolute, 0 0 0 0);
  @include size(100%);
  content: "";
  display: block;
  overflow: hidden;
  // Wistia will try to { position: relative } this element through
  // inline styles - we need to ensure this doesn't happen.
  position: absolute !important;
}

@each $background-name, $background-value in $backgrounds {
  .background--#{$background-name} {
    background: $background-value;
  }
}

.background__gallery,
.background__image {
  @include position(absolute, 0 0 0 0);
  @include size(100%);
  font-family: "object-fit: cover"; // Polyfill
  object-fit: cover;
  object-position: center;
}

.background__video {
  position: absolute;
}

.background__overlay {
  @include position(absolute, 0 0 0 0);
  @include size(100%);
  background-color: $color-black;
  opacity: 0.25;

  // If .background__image and .background__video are missing, hide overlay.
  &:only-child {
    display: none;
  }
}
