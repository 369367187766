/* webfonts\_gt-sectra.scss */

@font-face {
  font-family: "GT Sectra";
  src: url("../webfonts/gt-sectra/GT-Sectra-Display-Regular.eot"),
    url("../webfonts/gt-sectra/GT-Sectra-Display-Regular.woff") format("woff"),
    url("../webfonts/gt-sectra/GT-Sectra-Display-Regular.ttf")
      format("truetype");
  font-weight: $font-weight-regular;
  font-style: normal;
}
