/* components\_forms.scss */

.form {
  //
}

.form__group {
  margin-bottom: rem(20px);
}

.form__label {
  @include typography(hotel);
  cursor: pointer;
  display: block;
  margin-bottom: rem(5px);
}

.form__description {
  @include typography(india);
  color: $color-grey-medium-dark;
  display: block;
  margin-top: rem(10px);
}

.form__control {
  @include typography(base);
  appearance: none;
  background-color: $color-white;
  border: rem(1px) solid $color-border;
  border-radius: 0;
  box-sizing: border-box !important;
  display: block;
  padding: rem(12px) rem(12px * 1.5);
  width: 100%;

  &:hover,
  &:focus {
    //
  }

  &::placeholder {
    //
  }
}

.form__errors {
  @include reset-list;
  margin-bottom: rem(20px);
}

.form__error {
  @include typography(base);
  background-color: $color-error;
  border: rem(1px) solid $color-error;
  color: $color-white;
  margin-bottom: rem(10px);
  padding: rem(12px) rem(12px * 1.5);
}
