/* base\_zindex.scss */

.background {
  z-index: -1;
}

.browserupgrade {
  z-index: 100;
}

.modal {
  //
}

.modal__overlay {
  z-index: 10;
}

.modal__container {
  //
}

.section {
  //
}
