/* functions\_rem.scss */

// Convert pixels to rems
// eg. for a relational value of 12px write rem(12)
// Assumes $em-base is the font-size of <html>
// @see https://www.bourbon.io/docs/4/#px-to-rem

@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-unit($pxval);
  }

  $base: $em-base;
  @if not unitless($base) {
    $base: strip-unit($base);
  }
  @return ($pxval / $base) * 1rem;
}
