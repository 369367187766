/* components/_certificate.scss */

.certificate {
  &.is-available {
    //
  }

  &.is-unavailable {
    //
  }
}

.certificate__list {
  @include reset-list;
  @include rfs(rem(45px), padding);
  margin-bottom: rem(30px);

  .certificate.is-available & {
    background-color: $color-success;
    background-image: svg-url(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="27 80 457 352"><path fill="#{lighten($color-success, 5)}" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"></path></svg>'
    );
    background-position: center right;
    background-repeat: no-repeat;
    background-size: auto 150%;
  }

  .certificate.is-unavailable & {
    background-color: $color-error;
    background-image: svg-url(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 320 320"><path fill="#{lighten($color-error, 5)}" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>'
    );
    background-position: center right;
    background-repeat: no-repeat;
    background-size: auto 150%;
  }
}

.certificate__item {
  &:not(:last-child) {
    margin-bottom: rem(30px);
  }
}

.certificate__label {
  @include typography(echo);
  color: $color-white;
  display: block;
  margin-bottom: rem(5px);
}

.certificate__value {
  @include typography(hotel);
  color: $color-white;
  display: block;
}

.certificate__button {
  //
}

.certificate__footer {
  @include typography(india);
  color: $color-grey-medium-dark;
  text-align: center;

  a {
    color: inherit;
  }
}
