/* third-party\_browserupgrade.scss */

.browserupgrade {
  background-color: $color-black;
  color: $color-white;
  position: relative;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: rem(15px);
  padding-top: rem(15px);
  text-align: center;

  a {
    color: inherit;
  }
}
