/* components\_buttons.scss */

button {
  @include typography(base);
  @include reset-button;
  cursor: pointer;
}

.button {
  @include typography(kilo);
  $padding-vertical: 21.5px;
  $padding-horizontal: round($padding-vertical * 2);
  background: $color-grey-dark;
  border: rem(1px) solid $color-grey-dark;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  padding: rem($padding-vertical) rem($padding-horizontal);
  text-align: center;
  text-decoration: none;
  transition-duration: 0.2s;
  transition-property: background-color, border, color;
  vertical-align: middle;
  width: 100%;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: $color-white;
    border-color: $color-grey-dark;
    color: $color-grey-dark;
    text-decoration: none;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.is-loading {
    background-image: svg-url(
      '<svg width="44" height="44" xmlns="http://www.w3.org/2000/svg" stroke="#{$color-white}"><g fill="none" fill-rule="evenodd" stroke-width="2"><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/><animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/></circle><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/><animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/></circle></g></svg>'
    );
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
  }
}

.button--success {
  background: $color-success;
  border: rem(1px) solid $color-success;
  color: $color-white;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: $color-white;
    border-color: $color-success;
    color: $color-success;
  }
}
