/* components/_masthead.scss */

.masthead {
  background-color: $color-grey-dark;
  padding-bottom: rem(30px);
  padding-top: rem(30px);
  position: relative;
}

.masthead__container {
  //
}

.masthead__content {
  //
}

.masthead__logo {
  @include size(rem(221px), rem(100px));
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.masthead__logout {
  @include typography(foxtrot);
  @include position(absolute, rem(20px) rem(20px) null null);
  color: $color-white;
}
