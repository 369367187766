/* _config.scss */

// Colors
// -----------------------------------------------------------------------------

$color-black: #000000;
$color-grey-dark: #222222;
$color-grey-medium-dark: #686c6f;
$color-grey-medium: #acaeb0;
$color-grey-medium-light: #b8c1cb;
$color-grey-light: #e2e5e8;
$color-white: #ffffff;

$color-medium-dark-purple: #5d3a7a;
$color-medium-purple: #755592;
$color-medium-light-purple: #8c71ae;

$color-medium-dark-blue: #055fa0;
$color-medium-blue: #1672b4;
$color-medium-light-blue: #5d88b6;

$color-medium-dark-green: #18735a;
$color-medium-green: #3a8462;
$color-medium-light-green: #61977e;

$color-medium-dark-red: #901f2f;
$color-medium-red: #a02e3e;
$color-medium-light-red: #bb5160;

$color-border: $color-grey-medium;
$color-success: #59ce42;
$color-error: #ff4e31;

$colors: (
  black: $color-black,
  white: $color-white,
);

$backgrounds: (
  default: $color-white,
  alpha: darken($color-white, 5%),
  bravo: darken($color-white, 10%),
  charlie: darken($color-white, 15%),
  delta: darken($color-white, 20%),
);

// Typography
// -----------------------------------------------------------------------------

$font-family-serif: "GT Sectra", "Georgia", "Cambria", "Times New Roman",
  "Times", serif;
$font-family-sans-serif: "proxima-nova", "Helvetica Neue", "Helvetica", "Roboto",
  "Arial", sans-serif;

$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-book: 400;
$font-weight-regular: 400;
$font-weight-roman: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-demi: 700;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

@mixin line-height($font-size, $line-height) {
  line-height: strip-unit($line-height / $font-size);
}

// `<h1-giga>`
$font-size-giga: 150px;
$line-height-giga: 140px;

@mixin typography-scale-giga {
  @include font-size($font-size-giga);
  @include line-height($font-size-giga, $line-height-giga);
}

@mixin typography-style-giga {
  color: $color-grey-dark;
  font-family: $font-family-serif;
  font-weight: $font-weight-regular;
}

// `<h1-mega>`
$font-size-mega: 98px;
$line-height-mega: 106px;

@mixin typography-scale-mega {
  @include font-size($font-size-mega);
  @include line-height($font-size-mega, $line-height-mega);
}

@mixin typography-style-mega {
  color: $color-grey-dark;
  font-family: $font-family-serif;
  font-weight: $font-weight-regular;
}

// `<h1>`
$font-size-alpha: 64px;
$line-height-alpha: 74px;

@mixin typography-scale-alpha {
  @include font-size($font-size-alpha);
  @include line-height($font-size-alpha, $line-height-alpha);
}

@mixin typography-style-alpha {
  color: $color-grey-dark;
  font-family: $font-family-serif;
  font-weight: $font-weight-regular;
}

// `<h2>`
$font-size-bravo: 50px;
$line-height-bravo: 60px;

@mixin typography-scale-bravo {
  @include font-size($font-size-bravo);
  @include line-height($font-size-bravo, $line-height-bravo);
}

@mixin typography-style-bravo {
  color: $color-grey-dark;
  font-family: $font-family-serif;
  font-weight: $font-weight-regular;
}

// `<h3>`
$font-size-charlie: 44px;
$line-height-charlie: 56px;

@mixin typography-scale-charlie {
  @include font-size($font-size-charlie);
  @include line-height($font-size-charlie, $line-height-charlie);
}

@mixin typography-style-charlie {
  color: $color-grey-dark;
  font-family: $font-family-serif;
  font-weight: $font-weight-regular;
}

// `<h4>`
$font-size-delta: 38px;
$line-height-delta: 46px;

@mixin typography-scale-delta {
  @include font-size($font-size-delta);
  @include line-height($font-size-delta, $line-height-delta);
}

@mixin typography-style-delta {
  color: $color-grey-dark;
  font-family: $font-family-serif;
  font-weight: $font-weight-regular;
}

// `<h5>`
$font-size-echo: 30px;
$line-height-echo: 36px;

@mixin typography-scale-echo {
  @include font-size($font-size-echo);
  @include line-height($font-size-echo, $line-height-echo);
}

@mixin typography-style-echo {
  color: $color-grey-dark;
  font-family: $font-family-serif;
  font-weight: $font-weight-regular;
}

// `<h6>`
$font-size-foxtrot: 14px;
$line-height-foxtrot: 16px;

@mixin typography-scale-foxtrot {
  @include font-size($font-size-foxtrot);
  @include line-height($font-size-foxtrot, $line-height-foxtrot);
}

@mixin typography-style-foxtrot {
  color: $color-grey-dark;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-extrabold;
  letter-spacing: tracking(150);
  text-transform: uppercase;
}

// <p-large>
$font-size-golf: 22px;
$line-height-golf: 32px;

@mixin typography-scale-golf {
  @include font-size($font-size-golf);
  @include line-height($font-size-golf, $line-height-golf);
}

@mixin typography-style-golf {
  color: $color-grey-dark;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-regular;
}

// <p>
$font-size-hotel: 20px;
$line-height-hotel: 36px;

@mixin typography-scale-hotel {
  @include font-size($font-size-hotel);
  @include line-height($font-size-hotel, $line-height-hotel);
}

@mixin typography-style-hotel {
  color: $color-grey-dark;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-regular;
}

// <p-small>
$font-size-india: 16px;
$line-height-india: 26px;

@mixin typography-scale-india {
  @include font-size($font-size-india);
  @include line-height($font-size-india, $line-height-india);
}

@mixin typography-style-india {
  color: $color-grey-dark;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-regular;
}

// <small>
$font-size-juliett: 14px;
$line-height-juliett: 18px;

@mixin typography-scale-juliett {
  @include font-size($font-size-juliett);
  @include line-height($font-size-juliett, $line-height-juliett);
}

@mixin typography-style-juliett {
  color: $color-grey-dark;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-regular;
}

// <extra-small>
$font-size-kilo: 12px;
$line-height-kilo: 16px;

@mixin typography-scale-kilo {
  @include font-size($font-size-kilo);
  @include line-height($font-size-kilo, $line-height-kilo);
}

@mixin typography-style-kilo {
  color: $color-grey-dark;
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-extrabold;
  letter-spacing: tracking(150);
  text-transform: uppercase;
}

// ----------

$font-size-base: $font-size-hotel;
$line-height-base: $line-height-hotel;

@mixin typography-scale-base {
  @include typography-scale-hotel;
}

@mixin typography-style-base {
  @include typography-style-hotel;
}

// Media Queries
// -----------------------------------------------------------------------------

/**
 * Breakpoints used in the site, and provided to the `include-media` Sass mixin
 * @param  {List} a list of px values
 * @see http://include-media.com/documentation/#variable-breakpoints
 */
$breakpoints: (
  xsmall: 320px,
  small: 480px,
  medium: 768px,
  large: 1024px,
  xlarge: 1200px,
);

// Layout
// -----------------------------------------------------------------------------

/**
 * Breakpoints passed to the layout-generating mixin/functions.
 * @param  {List} a list of px values
 */
$layout-breakpoints: (xsmall, small, medium, large, xlarge);

/**
 * `max-width` of the layout elements (e.g. layout container)
 * @type {String} px value
 */
$layout-width: 1200px;

/**
 * Number of columns in the layout
 * @type {Number}
 */
$layout-columns: 12;

/**
 * Width of the gutter between layout elements
 * @type {String} px value
 */
$layout-gutter: 30px;
