/* components\_header.scss */

$header-padding: 90px;

.header {
  @include rfs(rem($header-padding), padding-bottom);
  @include rfs(rem($header-padding), padding-top);
  position: relative;
  text-align: center;
}

.header__container {
  margin-bottom: rem(-30px);
}

.header__background {
  background-color: $color-grey-dark;
}

.header__content {
  margin-bottom: rem(30px);
}

.header__heading {
  color: $color-white; // TODO
}

.header__subheading {
  color: $color-white; // TODO
}
