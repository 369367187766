/* base\_utility.scss */

.u-clearfix {
  @include clearfix;
}

.u-reset-list {
  @include reset-list;
}

.u-reset-button {
  @include reset-button;
}

.u-hidden {
  display: none;
}

.u-visually-hidden {
  @include hide-visually;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-circle {
  border-radius: 50%;
}

.u-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.u-separator {
  display: inline-block;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

@each $color-name, $color-value in $colors {
  .u-color-#{$color-name} {
    color: $color-value;
  }
}

$directions: (top, right, bottom, left);

@each $direction in $directions {
  .u-margin-#{$direction}-small {
    @include media(">medium") {
      margin-#{$direction}: rem($layout-gutter * 0.5);
    }
  }
  .u-margin-#{$direction} {
    @include media(">medium") {
      margin-#{$direction}: rem($layout-gutter);
    }
  }
  .u-margin-#{$direction}-large {
    @include media(">medium") {
      margin-#{$direction}: rem($layout-gutter * 1.5);
    }
  }
  .u-margin-#{$direction}-xlarge {
    @include media(">medium") {
      margin-#{$direction}: rem($layout-gutter * 1.5);
    }
    @include media(">xlarge") {
      margin-#{$direction}: rem($layout-gutter * 2);
    }
  }
}

/**
 * Dynamically generate visibility classes based off current breakpoints.
 */
@each $breakpoint in $breakpoints {
  .u-visible-#{nth($breakpoint, 1)} {
    @include media("<=#{nth($breakpoint, 1)}") {
      display: none;
    }
  }
  .u-hidden-#{nth($breakpoint, 1)} {
    @include media(">#{nth($breakpoint, 1)}") {
      display: none;
    }
  }
}

/**
 * Dynamically generate visibility classes based off current typography.
 */
@each $typography in typography-list() {
  .u-typography-scale-#{$typography} {
    @include typography-scale($typography);
  }
  .u-typography-style-#{$typography} {
    @include typography-style($typography);
  }
  .u-typography-#{$typography} {
    @include typography($typography);
  }
}
