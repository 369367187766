/* functions\_tracking.scss */

/**
 * Function for converting Adobe Photoshop tracking values into corresponding
 * em-based values, at a rate of 1000 = 1em.
 * @link https://scotch.io/tutorials/converting-photoshop-letter-spacing-to-css
 */
@function tracking($value) {
  @return (strip-unit($value) / 1000) * 1em;
}
