/* layout\_grid.scss */

/**
 * Breakpoints passed to the layout-generating mixin/functions.
 * @param  {List} a list of px values
 */
$layout-breakpoints: (xsmall, small, medium, large, xlarge) !default;

/**
 * `max-width` of the layout elements (e.g. layout container)
 * @type {String} px value
 */
$layout-width: 1200px !default;

/**
 * Number of columns in the layout
 * @type {Number}
 */
$layout-columns: 12 !default;

/**
 * Width of the gutter between layout elements
 * @type {String} px value
 */
$layout-gutter: 30px !default;

// .grid
// -----------------------------------------------------------------------------

@mixin grid {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 auto;
  max-width: rem($layout-width);
  padding: 0;
  width: 100%;

  @include media(">xsmall") {
    width: 90%;
  }

  @include media(">small") {
    width: 80%;
  }
}

.grid {
  @include grid;
}

@mixin grid-nested {
  margin-left: rem(-$layout-gutter / 2);
  margin-right: rem(-$layout-gutter / 2);
  width: auto;

  @include media(">xsmall") {
    width: auto;
  }

  @include media(">small") {
    width: auto;
  }
}

.grid .grid {
  @include grid-nested;
}

.grid--nested {
  @include grid-nested;
}

.grid--reverse {
  flex-direction: row-reverse;
}

.grid--align-left {
  justify-content: flex-start;
}

.grid--align-center {
  justify-content: center;
}

.grid--align-right {
  justify-content: flex-end;
}

.grid--align-justify {
  justify-content: space-between;
}

.grid--align-spaced {
  justify-content: space-around;
}

.grid--align-top {
  align-items: flex-start;
}

.grid--align-middle {
  align-items: center;
}

.grid--align-bottom {
  align-items: flex-end;
}

// .grid-cell
// -----------------------------------------------------------------------------

@mixin grid-cell {
  display: block;
  flex-basis: 100%;
  flex-grow: 1;
  max-width: 100%;
  padding-left: rem($layout-gutter / 2);
  padding-right: rem($layout-gutter / 2);
  width: auto;
}

.grid__cell {
  @include grid-cell;
}

@mixin grid-cell-width($column-width, $total-columns: $layout-columns) {
  flex-basis: percentage($column-width / $total-columns);
  max-width: percentage($column-width / $total-columns);
  width: auto;
}

@mixin grid-cell-offset($column-offset, $total-columns: $layout-columns) {
  margin-left: percentage($column-offset / $total-columns);
}

@mixin grid-cells($breakpoint) {
  @for $i from 1 through $layout-columns {
    .grid__cell--#{nth($breakpoint, 1)}-#{$i} {
      @include grid-cell-width($i);
    }
    .grid__cell--#{nth($breakpoint, 1)}-offset-#{$i} {
      @include grid-cell-offset($i);
    }
  }
}

@each $breakpoint in $breakpoints {
  $breakpoint-index: index($breakpoints, $breakpoint);
  @if $breakpoint-index == 1 {
    @include grid-cells($breakpoint);
  } @else {
    @include media(">#{nth($breakpoint, 1)}") {
      @include grid-cells($breakpoint);
    }
  }
}

// block-grid
// -----------------------------------------------------------------------------

@mixin block-grid($breakpoint) {
  @for $i from 1 through $layout-columns {
    $grid-cell-width: $layout-columns/$i;
    .grid--#{nth($breakpoint, 1)}-#{$i} > .grid__cell {
      @include grid-cell-width($grid-cell-width);
    }
  }
}

@each $breakpoint in $breakpoints {
  $breakpoint-index: index($breakpoints, $breakpoint);
  @if $breakpoint-index == 1 {
    @include block-grid($breakpoint);
  } @else {
    @include media(">#{nth($breakpoint, 1)}") {
      @include block-grid($breakpoint);
    }
  }
}
